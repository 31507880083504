<template>
  <div class="view">
    <top-nav active="3" :isRectangle="true" :istop="true"></top-nav>
    <div class="topnav_bg_90"></div>
    <div class="resources_detail">
      <div class="resources_detail_arctile">
        <div class="resources_detail_arctile_picture">
          <img :src="webblockObj.coverimage" alt="">
        </div>
        <p class="article_title">{{webblockObj.title}}</p>
        <p class="article_subtitle">{{webblockObj.subtitle}}</p>
        <p class="article_updatetime">{{handleAmPmDate(webblockObj.updatedtimestamp)}}</p>
        <div class="article_content" v-html="webblockObj.content"></div>
        <div class="back_to_resource" @click="goBack">Back to blogs</div>
      </div>
    </div>
    <div class="related_resources">
      <div class="resouces_list">
        <p class="related_title">Related blogs</p>
        <div class="block_item" v-for="(d,i) in blockListShow" @click="goRouter('/BlogDetails/'+d.webblockid)" :key="i">
          <div class="block_item_image">
            <img :src="d.coverimage+'?x-oss-process=image/resize,m_fill,h_140,w_289'" alt="">
          </div>
          <p class="block_item_title">{{d.title}}</p>
          <p class="block_item_updatetime">{{handleAmPmDate(d.updatedtimestamp)}}</p>
        </div>
      </div>
    </div>
    <foot-nav></foot-nav>
  </div>
</template>
<script>
import { getWebBlock } from '@/api/userApi.js'
import { AmPmDateNoTime } from '@/assets/js/time.js'

export default {
  data() {
    return {
      active: '1',
      blockList: [],
      webblockid: '',
      webblockObj: {},
      blockListShow: []
    }
  },
  created() {
    this.webblockid = this.$route.params.id;
    getWebBlock().then(res => {
      if (res.data.success) {
        this.blockList = res.data.data;
        this.fetchDate()
      }
    })
  },
  watch: {
    "$route": "fetchDate"
  },
  methods: {
    goRouter(path) {
      this.$router.push(path)
    },
    goBack() {
      // window.history.go(-1)
      this.$router.push('/Blogs')
    },
    fetchDate() {
      this.webblockid = this.$route.params.id;
      let arr = []
      this.blockList.forEach(element => {
        if (element.webblockid == this.webblockid) {
          this.webblockObj = JSON.parse(JSON.stringify(element))
        } else {
          arr.push(JSON.parse(JSON.stringify(element)))
        }
      });

      this.blockListShow = arr.slice(0, 3);
    },
    handleAmPmDate(times) {
      return AmPmDateNoTime(times)
    }
  },
  components: {
    "top-nav": resolve => require(["./TopNav.vue"], resolve),
    "foot-nav": resolve => require(["./FooterNav.vue"], resolve),
  },
}
</script>
<style lang="scss">
.view {
  // min-height: 100%;
  background: #f7f7f7;
}
.resources_detail {
  width: 1160px;
  min-height: 898px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  margin-top: 20px;
  .resources_detail_arctile {
    width: 760px;
    padding-bottom: 30px;
    .resources_detail_arctile_picture {
      border-radius: 5px;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
    .article_updatetime,
    .article_title,
    .article_subtitle {
      margin-top: 10px;
    }
    .article_title {
      margin-top: 20px;
      font-family: Poppins-Bold;
      font-size: 36px;
      // font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.17;
      letter-spacing: normal;
      text-align: left;
      color: #062440;
    }
    .article_subtitle {
      font-family: Poppins;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.3;
      letter-spacing: normal;
      text-align: left;
      color: #314455;
    }
    .article_updatetime {
      font-family: Poppins;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      color: #67788c;
    }
    .article_content {
      text-align: left;
      margin-top: 10px;
      p {
        margin-top: 10px;
        font-family: Poppins !important;
        font-size: 20px !important;
        font-weight: normal !important;
        font-stretch: normal !important;
        font-style: normal !important;
        line-height: 1.5 !important;
        letter-spacing: normal !important;
        text-align: left;
          // height: 1108px;
          align-self: stretch;
          flex-grow: 0;
          font-family: Poppins;
          font-size: 20px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
          color: #314455;
      }
    }
    .back_to_resource {
      margin-top: 30px;
      font-family: Poppins;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      color: #67788c;
      padding-bottom: 10px;
      width: 112px;
      border-bottom: 2px solid rgba(0, 0, 0, 0);
      transition: all 0.3s ease;
      &:hover {
        cursor: pointer;
        color: #0a7aff;
        border-bottom: 2px solid #0a7aff;
      }
    }
  }
}
.related_resources {
  left: 50%;
  top: 110px;
  width: 580px;
  height: 898px;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  .resouces_list {
    width: 309px;
    // height: 898px;
    padding: 10px;
    .related_title {
      font-family: Poppins;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #062440;
      margin-bottom: 20px;
    }
    .block_item {
      margin-bottom: 20px;
      &:hover {
        cursor: pointer;
      }
      .block_item_image {
        width: 289px;
        height: 140px;
        border-radius: 5px;
        overflow: hidden;
        img {
          width: 289px;
        height: 140px;
        }
      }
    }
    .block_item_title,
    .block_item_updatetime {
      margin-top: 10px;
    }
    .block_item_title {
      font-family: Poppins-Medium;
      font-size: 20px;
      transition: all 0.3s ease;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.3;
      letter-spacing: normal;
      text-align: left;
      color: #062440;
      text-overflow: ellipsis;
      // height: 26px;
      overflow: hidden;
      -webkit-line-clamp:2;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      &:hover {
        color: #0a7aff;
      }
    }
    .block_item_updatetime {
      font-family: Poppins;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      color: #67788c;
      margin-bottom: 56px;
    }
  }
}
</style>